import { Injectable } from '@angular/core';
import { EndPoints } from '../../api/endpoint';
import { serverIp } from '../../api/config';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private privateKeyUrl: string = serverIp() + EndPoints.privateKey;
  private repwdsubmitUrl: string = serverIp() + EndPoints.resetPassword;
  private LockStatusUrl: string = serverIp() + EndPoints.getAllTransfersLockStatus;
  private LockUpdateStatusUrl: string = serverIp() + EndPoints.UpdateTransferLockStatus;
  private getOwnerUrl : string = serverIp() + EndPoints.getOwner;
  constructor(private http: HttpClient) { }

  submitPrivateKey(data) {
    return this.http.post(this.privateKeyUrl, data);
  }


  resetPassword(data) {
    return this.http.post(this.repwdsubmitUrl, data);
  }
  LockStatus() {
    return this.http.get(this.LockStatusUrl);
  }
  LockUpdate(data) {
    return this.http.get(this.LockUpdateStatusUrl + '?Status=' + data);
  }

  getOwner(data) {
    return this.http.get(this.getOwnerUrl + data);
  }
}
