import { Component } from '@angular/core';
import { User } from '../model/user';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { fadeAnimation } from './animations/router-outlet-animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']

})
export class AppComponent {
  currentUser: User;
  title = 'Golden Goose';
  islogin: boolean = false;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(user => this.currentUser = user);
  }

}
