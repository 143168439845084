export enum EndPoints {
    ACCOUNT_LOGIN = 'api/User/signin',
    ACCOUNT_TWOFACTOR = 'api/User/CheckAuth',
    ACCOUNT_REGISTER = 'api/Account/register',
    ACCOUNT_LogOut = 'api/User/LogOut',
    GetUserDataByStatus = 'api/User/GetUserDataByStatus?Status=true',
    WalletByUserId = 'api/Wallet/WalletByUserId?UserId=',
    TransferToken = 'api/Transaction/TransferToken',
    TransactionDetails = 'api/Transaction/TransactionDetails',

    BackTrackRequest = 'api/Transaction/BackTrackRequest',
    LockingTimeUpdate = 'api/Transaction/LockingTimeUpdate',

    resetPassword = 'api/User/ChangePassword',
    privateKey = 'api/User/Activateprivatekey',
    dashboard = 'api/Transaction/GetDashBoard',

    // =======================
    GetUserData = 'api/User/GetUserData',
    GetUserReportData = 'api/User/GetUserDataValue',
    GetUserType = 'api/User/GetUserType',
    userReport = 'api/Transaction/userReport',
    TransactionDetailsReport = 'api/Transaction/TransactionReport',
    //===========================================
    ADD_USER_TYPE = 'api/User/AddUserType',
    GET_USER_TYPE = 'api/User/GetUserType',
    UPDATE_USER_TYPE_STATUS = 'api/User/UpdateUserTypeStatus',
    GET_USER = 'api/data/GetUserData',
    ADD_USER = 'api/User/AddUsers',
    GET_USER_BY_STATUS = 'api/User/GetUserTypeByStatus',
    UPDATE_USER = 'api/User/UpdateUser',
    UPDATE_USER_STATUS = 'api/User/UpdateUserStatus',
    GET_WALLET = 'api/Wallet/WalletByUserId',
    ADD_WALLET = 'api/Wallet/AddWallet',
    GET_USERDATA_BY_STATUS = 'api/User/GetUserDataByStatus',
    UPDATE_WALLET = 'api/Wallet/UpdateWallet',
    getAllTransactionUrl = 'api/Transaction/GetAllTransactions',
    BackTrackLiveTransaction = 'api/Transaction/BackTrackLiveTransaction',
    getAllTransfersLockStatus = 'api/Transaction/getAllTransfersLockStatus',
    UpdateTransferLockStatus = 'api/Transaction/UpdateTransferLockStatus',
    getOwner = 'api/Transaction/getOwner?walletAddress='

}