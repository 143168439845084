import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegexService {

  constructor() { }

  // fullNameRegex: any = new RegExp(/^[a-zA-Z]+(\s+[a-zA-Z]+)*$/); // space not is allowwed
  // fNameRegex: any = new RegExp(/^[a-zA-Z\s]*$/); // space is allowwed
  // lNameRegex: any = new RegExp(/^[a-zA-Z\s]*$/);
  // contactRegex: any = new RegExp(/[2-9]{1}\d{8}/);
  // emailRegex: any = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  // passwordRegex: any = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
  // phoneNUmber: any = RegExp(/^[a-zA-Z0-9]{8,16}$/);
  // accountName: any = RegExp(/^[0-9A-Za-z ]+$/);
  // accountNumber: any = RegExp(/^\d+$/);
  // swiftCode: any = RegExp(/^[0-9A-Za-z]+$/)
  // coinDigits: any = RegExp(/^[0-9~$]\d*(\.\d+)?$/)
  // postalcode: any = RegExp(/^[a-zA-Z0-9]{4,10}$/)


  // =======================================
  isAlphaSpace: any = new RegExp(/^[a-zA-Z ]*$/); //with space
  isAlphaSpaceMess: any = "Should contain alphabets characters only with whitespace";
  isAlpha: any = new RegExp(/^[a-zA-Z]*$/); //without space
  isAlphaMess: any = "Should contain alphabets characters only without whitespace";
  isAlphaNumericSpace: any = new RegExp(/^[a-zA-Z0-9_. ]*$/); // with dot and underscore
  isAlphaNumericSpaceMess: any = "Should contain alphabets characters only with whitespace";
  isAlphaNumeric: any = new RegExp(/^[a-zA-Z0-9]*$/); // without dot and underscore
  isAlphaNumericMess: any = "Should contain alphabets characters only.";
  onlyNumber: any = RegExp(/^[0-9]*$/);
  onlyNumberMess: any = "Please enter only digits.";

  emailRegex: any = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);



  //DEFAULT MESSAGE
  required: any = "Please enter the field.";
  requiredImg: any = "Please select the image.";
  requiredemail: any = "Please enter valid email address.";
}
