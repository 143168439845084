import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private tostr: ToastrService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let token = localStorage.getItem("token");
        if (token) {
            return true;
        }
        else {
            this.router.navigate(['/login']);   ///, { queryParams: { returnUrl: state.url }}
            this.tostr.error('Please logIn.');
            return false;
        }
    }
}