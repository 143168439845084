import { Injectable } from '@angular/core';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from '../../api/config';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs'
declare let window: any;
@Injectable({
  providedIn: 'root'
})
export class MetamaskService {
  metaMaskStatus: string = 'Get MetaMask';
  web3: any;
  enable: any;
  private txnByDetails = new Subject<any>();
  txnByAddress: Observable<any>;
  constructor(private toster:ToastrService) {
    this.txnByAddress = this.txnByDetails.asObservable();

   }


  async onNetwirkChangeREload() {
    const ethereum: any = await detectEthereumProvider();
  
    ethereum
    if (ethereum) { 
      ethereum.on('chainChanged', (chainId:any) => {
        window.location.reload();
      });
    }
  }


  checkMetaMaskInitial(): void {
    if (window.ethereum === undefined) {
      this.metaMaskStatus = 'Get MetaMask';
    } else {
      this.metaMaskStatus = 'Connect Wallet';
    }
  }

  

  metaMaskUpdate(){
    this.metaMaskStatus = 'Connect Wallet';
  }
  metaMaskUpdated(){
    this.metaMaskStatus = 'Wallet Connected';
  }
  //Connect to Metamask Wallet (FOR WEB)
  initMetaMask(): void {
    if (this.metaMaskStatus !== 'Wallet Connected') {
      if (window.ethereum === undefined) {
        // this.toster.error('Non-Ethereum browser detected. Install MetaMask', '', { closeButton: true, progressBar: true });
        this.metaMaskStatus = 'Get MetaMask';
      } else {
        if (typeof window.web3 !== 'undefined') {
          this.web3 = new Web3(window.web3.currentProvider);
        } else {
          this.web3 = new Web3.providers.HttpProvider(appConfig.web3ProviderUrl);
        }
        
        window.web3 = new Web3(window.ethereum);
        this.enable = this.enableMetaMaskAccount();
        
        if (window.ethereum) {
        
           

          // Subscriptions register
          window.ethereum.on('accountsChanged', async (accounts: []) => {
            this.txnByDetails.next(new Date())
              location.reload();
          });
          window.ethereum.on('networkChanged', async (network: number) => {
              location.reload();
          });      
    }
      }
    }
    else {
      this.toster.info("Wallet Already Connected");
    }

  }


  


  enableMetaMaskAccount(): any {
    let enable = false;
    enable = window.ethereum.enable();
  }


  



//Connect to Metamask Wallet (FOR MOBILE)
  async walletProvider() {
    // this.headerService.connectWalletFromDashboard.next(null);
  const provider: any = new WalletConnectProvider({
      infuraId: appConfig.infuraId,
      qrcodeModalOptions: {
        mobileLinks: [
          "metamask",
          "trust",
        ],
      },
    });
    await provider.enable();
  
    this.web3 = new Web3(provider);
    const accounts = await this.web3.eth.getAccounts();
    if (accounts[0]) {
      // this.metaMaskConnected = true;
      // this.accountAddress = accounts[0];
      sessionStorage.setItem('account', accounts[0]);
      // this.connectStatus = true;
      if (!localStorage.getItem('walletConnected')) {
        localStorage.setItem('walletConnected', 'success');
        location.reload();
      }
    };
  
  
    provider.on("disconnect", (code: number, reason: string) => {
      console.log(code, reason);
      sessionStorage.removeItem('account');
      if (code == 1000) {
        // this.headerService.web3Instance.next(false);
        // this.metaMaskConnected = false;
        sessionStorage.removeItem('account');
        localStorage.removeItem('walletConnected');
        location.reload();
        // this.connectStatus = false;
      }
    })
  
    // this.headerService.web3Instance.next(true);
    provider.on('accountsChanged', (accounts: string[]) => {
      // this.accountAddress = accounts[0];
      sessionStorage.setItem('account', accounts[0]);
      localStorage.setItem('walletConnected', 'success');
      // this.connectStatus = true;
      location.reload();
    });

    provider.on('networkChanged', async (network: number) => {
      location.reload();
      // this.connectStatus = true;
  });
  provider.on('onConnect', async (network: number) => {
    location.reload();
    // this.connectStatus = true;
});
  }
}
