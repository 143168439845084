import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
//

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'header',
    loadChildren: () => import('./header/header.module').then(m => m.HeaderModule)
  },
  {
    path: 'PrivateKey',
    loadChildren: () => import('./private-key/private-key.module').then(m => m.PrivateKeyModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'Settings',
    loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  }, {
    path: 'Users',
    loadChildren: () => import('./add-user/add-user.module').then(m => m.AddUserModule),
    canActivate: [AuthGuard]
  }, {
    path: 'userType',
    loadChildren: () => import('./user-type/user-type.module').then(m => m.UserTypeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'userWallet/:userid',
    loadChildren: () => import('./user-wallet/user-wallet.module').then(m => m.UserWalletModule),
    canActivate: [AuthGuard]
  }, {
    path: 'transfertoken',
    loadChildren: () => import('./transfertoken/transfertoken.module').then(m => m.TransfertokenModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'transaction',
    loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule),
    canActivate: [AuthGuard]
  }, {
    path: 'livetransactions',
    loadChildren: () => import('./admin-all-transaction/admin-all-transaction.module').then(m => m.AdminAllTransactionModule),
    canActivate: [AuthGuard]
  }, {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }