
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, tap } from "rxjs/operators";
import { Injectable, ViewContainerRef } from "@angular/core"
import { Router } from "@angular/router";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { serverIp } from '../../api/config';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toastr: ToastrService, private spinner: NgxSpinnerService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let endPoint = serverIp();

        if (req.url.startsWith(endPoint)) {
            // this.spinner.show();
        }
        let success;
        const token: string = localStorage.getItem('token');
        // let currentUser = JSON.parse(value);
        if (token) {
            // console.log(token)
            req = req.clone({ headers: req.headers.set('Authorization', "Bearer " + token) });
        }
        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

        let handleRequest: Observable<HttpEvent<any>> = next.handle(req);

        return handleRequest.pipe(
            tap((success: any) => {
                setTimeout(() => {

                    // this.spinner.hide();
                }, 3000);


            }),
            catchError((err: any) => {
                if (err.status == 401) {

                    localStorage.clear();
                    this.router.navigateByUrl('/login');
                    this.toastr.error('Please Sign In.');


                }

                else if (err.status == 500) {
                    // console.log('Some server error has occured. Please try again later.');
                    // document.getElementById('spinner').style.display = 'none';
                    // this.errorService.addErrors([{'error':'Some server error has occured. Please try again later.','status':'500'}]);
                }
                setTimeout(() => {

                    // this.spinner.hide();
                }, 3000);

                return observableThrowError(err);

            })
        );
    }

}




