import { Component, OnInit, TemplateRef , ElementRef ,ViewChild} from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegexService } from '../regex.service';
import { HeaderService } from './header.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DeviceDetectorService } from 'ngx-device-detector';
import { MetamaskService } from '../services/metamask.service';
// import { ModalDirective } from 'angular-bootstrap-md';

import { appConfig } from '../../api/config'
import Web3 from 'web3';
declare let window: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // @ViewChild("walltModal" ,{static: false}) walltModal: ElementRef;
  @ViewChild('walltModal', { static: true }) walltModal: TemplateRef<any>;

  token = 'sdfsdf';
  temp;
  message;
  currentlockSatus;
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: "lsModals mt-3 pt-3 mt-md-5 pt-md-5"
  };
  privateKeyFormcsubmitted = false;
  resetsubmitted = false;
  lockUnlockSubmitted = false;
  resetPasswordform: FormGroup;
  privateKeyFormc: FormGroup;
  // lockUnlockForm: FormGroup;
  constructor(private authenticationService: AuthenticationService, 
              private router: Router, 
              private tostr: ToastrService, 
              private modalService: BsModalService, 
              private regexService: RegexService, 
              private service: HeaderService, 
              private spinner: NgxSpinnerService,
              private deviceService: DeviceDetectorService,
              private metamask:MetamaskService) {
    this.token = localStorage.getItem('token');

    

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if (isMobile) {  this.isDeviceMobile = true;  }
    if (isTablet) {  this.isDeviceMobile = true;  }
  }
  isDeviceMobile=false;
  web3: any;
  walletaddress;
  ngOnInit() {
       
    if (window.ethereum === undefined) {
      this.modalRef = this.modalService.show(this.walltModal, this.config);
    } else {
      if (this.isDeviceMobile) {
        this.metamask.walletProvider();
      } else if (localStorage.getItem('walletconnect')) {
        this.metamask.walletProvider();
      }
      else {
        this.metamask.initMetaMask();
      }
    }

    
    // if(sessionStorage.getItem('account')){
    //   this.metamask.checkMetaMaskInitial();
    //   this.metamask.initMetaMask();
    // }

    if (typeof window.web3 !== 'undefined') {
      this.web3 = new Web3(window.web3.currentProvider);
    } else {
      this.web3 = new Web3.providers.HttpProvider(appConfig.web3ProviderUrl);
    }


    this.initPrivateKeyFrom();
    this.initResetForm();
    // this.initlockUnlockFrom();
    this.message = this.regexService;
    this.temp = true;
    this.token = localStorage.getItem('token');
    this.test = true;
  }

  test;
  btnConnectWalllet=true;
  btnWalletAddress=false;
  ngDoCheck(){
    if (!this.isDeviceMobile && window.ethereum && window.ethereum._state && window.ethereum._state.accounts && window.ethereum._state.accounts.length) {
      if (window.ethereum._state.accounts[0] === undefined) { window.ethereum
        this.metamask.metaMaskUpdate();
      } else {
        this.metamask.metaMaskUpdated();
          sessionStorage.setItem('account', window.ethereum._state.accounts[0]);
          let add:any = sessionStorage.getItem('account');
          let start = add.substring(0, 6);
          let end = add.slice(-4);
          sessionStorage.setItem('accountAddressShort' , start+"...."+end);
          // this.CheckgetOwnerShip();
      }
    } else if(localStorage.getItem('-walletlink:https://www.walletlink.org:Addresses')){
    }else if(localStorage.getItem('walletconnect')){
    }else  {
      sessionStorage.clear();
      this.metamask.checkMetaMaskInitial();
      // this.metamask.initMetaMask();
    }
    this.token = localStorage.getItem('token');

    if(sessionStorage.getItem('account') && sessionStorage.getItem('accountAddressShort')){
      this.btnConnectWalllet=false;
      this.btnWalletAddress=true;
      this.walletaddress = sessionStorage.getItem('accountAddressShort')
    } else{
      this.btnConnectWalllet=true;
      this.btnWalletAddress=false;
      this.walletaddress="";
    }
    this.CheckgetOwnerShip()
  }

  CheckgetOwnerShip (){
    if(this.test){
      if(sessionStorage.getItem('account')){
        this.service.getOwner(sessionStorage.getItem('account')).subscribe((result:any)=>{
          console.log(result.value);
          if(result.value.ReturnMessage == "Success"){
            sessionStorage.setItem('isowner' , "this wallet address has ownership" );
            this.test=false;
          } else{
            sessionStorage.removeItem('isowner')
          }
          
        })
      } 
      this.test=false;
    }
  }


  
  // ==================== lockunlock form ==================
  // initlockUnlockFrom() {
  //   this.lockUnlockForm = new FormGroup({
  //     lockUnlock: new FormControl(false)
  //   });
  // }

  updateLock() {
    let lockControl = this.resetPasswordform.controls;
    this.spinner.show();

    this.service.LockUpdate(!lockControl.lockUnlock.value).subscribe((success) => {
      this.tostr.success(success['value']);
      this.spinner.hide();
      this.resetPasswordform.controls['lockUnlock'].setValue(lockControl.lockUnlock.value);
    }, (error) => {
      this.spinner.hide();
      this.resetPasswordform.controls['lockUnlock'].setValue(!lockControl.lockUnlock.value);
      this.tostr.error(error['error'].value);
    });
  }
  // ==================== private form ==================
  initPrivateKeyFrom() {
    this.privateKeyFormc = new FormGroup({
      privateKey: new FormControl('', [Validators['required'], Validators.pattern(this.regexService.isAlphaNumeric)])
    });
  }

  privateKeyFormcsubmit() {
    this.privateKeyFormcsubmitted = true;
    if (this.privateKeyFormc.valid) {
      this.spinner.show();
      let control = this.privateKeyFormc.controls
      let data = {
        "privateKey": control.privateKey.value
      };
      this.service.submitPrivateKey(data).subscribe((success) => {
        this.spinner.hide();
        this.tostr.success(success['value']);
        this.closeModal();

      },
        (error) => {
          this.spinner.hide();
          this.tostr.error(error['error'].value);
        }

      );
    }
    else {
      // console.log('oko');

    }


  }

  // =========== reset pwd==============
  initResetForm() {
    this.resetPasswordform = new FormGroup({
      currentPwd: new FormControl('', Validators['required']),
      newPwd: new FormControl('', Validators['required']),
      confirmPwd: new FormControl('', Validators['required']),
      lockUnlock: new FormControl(false)
    });

  }
  resetsubmit() {
    this.resetsubmitted = true;
    if (this.resetPasswordform.valid) {
      this.spinner.show();
      const resetcontrol = this.resetPasswordform.controls;
      const resetdata = {
        oldPassword: resetcontrol.currentPwd.value,
        newPassword: resetcontrol.newPwd.value,
        confirmPassword: resetcontrol.confirmPwd.value,

      };


      this.service.resetPassword(resetdata).subscribe((success) => {
        this.spinner.hide();
        this.tostr.success(success['value']);
        this.closeModal();
        this.logout();
      },
        (error) => {
          this.spinner.hide();
          this.tostr.error(error['error'].value);
        }

      );
    }
  }
  // ====================
  addModal(template: TemplateRef<any>) {
    this.resetPasswordform.reset();
    this.spinner.show();
    this.service.LockStatus().subscribe((success) => {
      this.spinner.hide();
      this.currentlockSatus = success['value'].status;
      // console.log(this.currentlockSatus);

      this.resetPasswordform.controls['lockUnlock'].setValue(this.currentlockSatus);
    },
      (error) => {
        this.tostr.error(error['error'].value);
        this.spinner.hide();
        this.closeModal();
      });
    this.modalRef = this.modalService.show(template, this.config);
  }
  privateKeyOpen(template: TemplateRef<any>) {
    this.privateKeyFormc.reset();
    this.modalRef = this.modalService.show(template, this.config);
  }
  // lockUnlockopen(template: TemplateRef<any>) {
  //   this.service.LockStatus().subscribe((success) => {
  //     this.currentlockSatus = success['value'].status;
  //     console.log(this.currentlockSatus);

  //     this.resetPasswordform.controls['lockUnlock'].setValue(this.currentlockSatus);
  //   });
  //   this.modalRef = this.modalService.show(template, this.config);
  // }
  closeModal() {
    this.privateKeyFormcsubmitted = false;
    this.resetsubmitted = false;
    this.modalRef.hide();

  }


  logout() {
      localStorage.clear();
      sessionStorage.clear()
      this.router.navigateByUrl('/login');
    // this.spinner.show();
    // this.authenticationService.logout().subscribe((success) => {
    //   this.spinner.hide();
    //   localStorage.clear();
    //   sessionStorage.clear()
    //   this.router.navigateByUrl('/login');
    // }, (error) => {
    //   this.spinner.hide();
    //   this.tostr.success(error['error'].value);
    // });

  }



    

  connectMetamask(template: TemplateRef<any>) {
    if (window.ethereum === undefined) {
      this.modalRef = this.modalService.show(template, this.config);
    } else {
      if (this.isDeviceMobile) {
        this.metamask.walletProvider();
      } else if (localStorage.getItem('walletconnect')) {
        this.metamask.walletProvider();
      }
      else {
        this.metamask.initMetaMask();
      }
    }

  }




}
