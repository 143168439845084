import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../model/user';
import { serverIp } from '../../api/config';
import { EndPoints } from '../../api/endpoint';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private loginUrl: string = serverIp() + EndPoints.ACCOUNT_LOGIN;
    private twoFactorUrl: string = serverIp() + EndPoints.ACCOUNT_TWOFACTOR;
    private logoutUrl: string = serverIp() + EndPoints.ACCOUNT_LogOut;
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(data) {
        return this.http.post<any>(this.loginUrl, data);
    }
    twoFactor(data) {
        return this.http.post<any>(this.twoFactorUrl, data);
    }

    logout() {

        return this.http.get(this.logoutUrl);
    }
}